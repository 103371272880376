<template>
  <div class="header-com">
    <router-link to="/homepage">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
        <span>二十三点心理测试</span>
      </div>
    </router-link>
    <div class="login">
      <router-link to="/loginpage">
        <span v-show="login === false">登录</span>
      </router-link>
      <router-link to="/registerpage" style="margin-left: 10px">
        <span v-show="login === false">注册</span>
      </router-link>
      <span v-show="login === true">{{ nickName }}</span>
      <span v-show="login === true">，你好！ </span>
      <span class="logout" v-show="login === true" @click="logout">退出</span>
    </div>
  </div>
</template>

<script>
import bus from "@/js/bus";
export default {
  name: "HeaderCom",
  data() {
    return {
      login: false,
      nickName: "",
      showMenu: false,
    };
  },
  watch: {
    // $route: function () {
    //   bus.$on("username", (username) => {
    //     this.nickName = username;
    //     this.login = true;
    //   });
    // },
    $route() {
      if (localStorage.getItem("username")) {
        this.nickName = localStorage.getItem("username");
        this.login = true;
      }
    },
  },
  beforeDestroy() {
    bus.$off("username");
  },
  methods: {
    // 路由跳转函数
    gotoTestPage() {
      this.$router.replace("/homepage").catch((err) => {
        console.log();
      });
    },
    gotoLoginPage() {
      this.$router.replace("/loginpage").catch((err) => {
        console.log();
      });
    },
    gotoRegisterPage() {
      this.$router.replace("/registerpage").catch((err) => {
        console.log();
      });
    },
    gotoResultPage() {
      this.$router.replace("/resultpage").catch((err) => {
        console.log();
      });
    },
    gotoDocPage() {
      this.$router.replace("/docpage").catch((err) => {
        console.log();
      });
    },
    logout() {
      localStorage.removeItem("username");
      localStorage.removeItem("formResult");
      if (window.location.pathname === "/homepage") {
        window.location.reload(); // 如果当前页面是homepage，则刷新页面
      } else {
        window.location.href = "/homepage"; // 否则跳转到homepage
      }
    },
  },
};
</script>

<style scoped>
/* PC */
@media screen and (min-width: 1200px) {
  .header-com {
    position: fixed;
    z-index: 3;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 80px;
  }

  .header-com .logo {
    width: 300px;
    position: relative;
    right: 100px;
    font-size: 24px;
    line-height: 80px;
    text-align: left;
  }

  .header-com .logo > img {
    position: relative;
    top: 6px;
    width: 28px;
  }

  .login {
    position: relative;
    width: 200px;
    text-align: center;
    line-height: 80px;
    left: 100px;
  }

  .logout {
    font-size: 12px;
    color: skyblue;
    cursor: pointer;
  }
}

/* vue过渡 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
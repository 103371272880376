import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//配置路由

export default new VueRouter({
  // mode: 'history', // 切换为 history 模式
  // base: '/psych-web/', // 设置基础路径为 /psych/
  routes: [
    {
      path: '/formcom',
      component: () => import('@/pages/FormCom')
    },
    {
      path: '/scorequestioncom',
      component: () => import('@/pages/ScoreQuestionCom'),
      props: true
    },
    {
      path: '/optionquestioncom',
      component: () => import('@/pages/OptionQuestionCom'),
      props: true
    },
    {
      path: '/homepage',
      component: () => import('@/pages/HomePage'),
    },
    {
      path: '/docpage',
      component: () => import('@/pages/DocPage')
    },
    {
      path: '/loginpage',
      component: () => import('@/pages/LoginPage')
    },
    {
      path: '/registerpage',
      component: () => import('@/pages/RegisterPage')
    },
    {
      path: '/resultpage',
      component: () => import('@/pages/ResultPage')
    },
    //重新定向
    {
      path: '*',
      redirect: '/homepage'
    }
  ]
});
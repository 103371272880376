<template>
  <div class="footer-com">

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .footer-com {
    height: 200px;
    background-color: rgb(99, 99, 99);
  }
</style>